import React from 'react';
import styled, { css } from 'styled-components';

import { getComponentSettings } from 'libs/content';
import MaxWidth from 'layouts/max-width';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Columns from 'parts/basic-content/Columns';
import Article from 'components/Article';
import LazyImage from 'components/LazyImage';

const Wrapper = styled.div`
	// Articles in grid (multiple per row)
	${p =>
		p.direction === 'vertical' &&
		css`
			${p =>
				p.theme.media.mediumOnly(css`
					display: grid;
					gap: 40px;
					grid-template-columns: ${p =>
						(p.perrow === 1 && `repeat(${p.perrow}, 1fr)`) ||
						'repeat(2, 1fr)'};
				`)}
			${p =>
				p.theme.media.large(css`
					display: grid;
					gap: 40px;
					grid-template-columns: ${p =>
						(p.perrow && `repeat(${p.perrow}, 1fr)`) ||
						'repeat(2, 1fr)'};
				`)}

			${p =>
				p.theme.media.smallOnly(css`
					.component__article {
						margin: 0 0 45px;
					}
				`)}

			.component__article--media {
				padding-bottom: ${p => p.theme.spacing.desktop.xsmall};
			}

			.component__article--text {
				.text {
					p > span {
						font-weight: 400 !important;
					}
					p:last-of-type {
						margin-bottom: 0;
					}
				}
				.buttons {
					button {
						align-items: flex-start;
						text-align: left;
					}
				}
			}
		`}
	// Articles with horizontal direction (1 per row, image alternating right/left)
	${p =>
		p.direction === 'horizontal' &&
		p.perrow === 1 &&
		css`
			> .spacing:last-of-type {
				padding-bottom: 0;
			}
			.component__article {
				padding-bottom: ${p => p.theme.spacing.desktop.large};
				&:last-of-type {
					padding-bottom: 0 !important;
				}
				${p =>
					p.theme.media.mediumOnly(css`
						padding-bottom: ${p => p.theme.spacing.desktop.large};
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						padding-bottom: ${p => p.theme.spacing.desktop.medium};
						.component__article--media {
							padding-bottom: ${p =>
								p.theme.spacing.desktop.xsmall};
						}
					`)}
				${p =>
					p.theme.media.medium(css`
						&:nth-child(even) {
							flex-direction: row-reverse;
							.component__article--media {
								padding: 0 0 0 20px;
							}
							.component__article--text {
								padding: 0 20px 0 0;
							}
						}
					`)}
			}
		`}
	.buttons {
		margin-top: 10px;
	}
`;

const Images = styled.div`
	display: grid;
	gap: 40px;
	grid-template-columns: ${p =>
		(p.perrow?.desktop && `repeat(${p.perrow?.desktop}, 1fr)`) ||
		'repeat(6, 1fr)'};
	${p =>
		p.theme.media.mediumOnly(css`
			gap: 30px;
			grid-template-columns: ${p =>
				(p.perrow?.tablet && `repeat(${p.perrow?.tablet}, 1fr)`) ||
				'repeat(3, 1fr)'};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			gap: 30px;
			grid-template-columns: ${p =>
				(p.perrow?.mobile && `repeat(${p.perrow?.mobile}, 1fr)`) ||
				'repeat(2, 1fr)'};
		`)}
`;

const Image = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

/**
 * Represents a grid/list of articles
 * @param {string} layout - The layout variant of the grid/default
 * @param {array} relatedContent - The articles
 * @param {array} settings - The settings of the component
 * @param {object} group - The groups to show articles from
 * @param {integer} perrow - The number of articles per row
 * @param {integer} max - The maximum number of articles to show from chosen groups
 * @param {string} title - The title of the component
 * @param {object} intro - The introduction text of the component
 * @param {array} buttons - The buttons below the component
 * @param {string} headinglevel - The heading level of the title in the component
 * @param {boolean} showTags - Determines whether tags should be shown.
 * @param {...object} props - The rest of the parameters for the component
 */
export default function RelatedContent({
	layout = 'Rutenett',
	relatedContent = [],
	settings = [],
	group,
	perrow = 3,
	max,
	title,
	intro,
	buttons = [],
	headinglevel = 'h2',
	showTags = false,
	...props
}) {
	// Set the variant of the component
	const direction =
		layout === 'Bilde og tekst på annenhver rad'
			? 'horizontal'
			: 'vertical';

	const componentSettings = {
		...getComponentSettings({ settings }),
		showTags,
		direction,
		perrow: (direction === 'horizontal' && 1) || perrow || 3,
	};

	// Check if all objects in relatedContent-array has __typename "ContentfulKomponentBilde"
	const allImages = relatedContent?.every(
		x => x?.__typename === 'ContentfulKomponentBilde'
	);

	// If allImages is false, then set perrow to 3 if perrow is greater than 3, otherwise set perrow to the value of perrow
	if (!allImages) {
		componentSettings.perrow = perrow > 3 ? 3 : perrow;
		if (direction === 'horizontal') {
			componentSettings.perrow = 1;
		}
	} else {
		componentSettings.perrow = perrow;
	}

	// Randomize the order of the entries if randomize is true, otherwise return the array as is
	let entries = randomizeEntries(
		relatedContent || [],
		componentSettings?.randomize === 'true'
	).slice(0, max);

	// If the max number of entries is set, then set availableSpots to the difference between max and the length of the entries array, otherwise set availableSpots to the length of the entries array
	let availableSpots = typeof max ? max - entries?.length : entries?.length;

	/**
	 * Blogginnlegg:
	 * If there are available spots, sort the array by either random order or date,
	 * slice the array to the max number of entries and push the entries to the entries array.
	 * Redeclare availableSpots to the difference between max and the length of the entries array
	 **/
	if (
		(max && availableSpots > 0 && group?.innhold___innlegg?.length > 0) ||
		(group?.innhold___innlegg?.length > 0 && !max)
	) {
		let blogPosts = sortByDate(
			group?.innhold___innlegg,
			componentSettings?.randomize === 'true'
		);
		if (max) {
			entries.push(...blogPosts.slice(0, availableSpots));
			availableSpots = max - entries.length;
		} else {
			entries.push(...blogPosts);
		}
	}

	/**
	 * Suksesshistorie:
	 * If there are available spots, randomize the array if randomize is true,
	 * slice the array to the max number of entries and push the entries to the entries array
	 **/
	if (
		(max &&
			availableSpots > 0 &&
			group?.innhold___suksesshistorie?.length > 0) ||
		(group?.innhold___suksesshistorie?.length > 0 && !max)
	) {
		let successStories = randomizeEntries(
			group?.innhold___suksesshistorie,
			componentSettings?.randomize === 'true'
		);
		if (max) {
			entries.push(...successStories.slice(0, availableSpots));
			availableSpots = max - entries.length;
		} else {
			entries.push(...successStories);
		}
	}

	// Remove duplicates
	entries = removeDuplicates(entries);

	// If the number of entries is less than the number of entries per row, then set perrow to the number of entries
	if (!allImages && entries?.length < componentSettings.perrow) {
		componentSettings.perrow = entries?.length;
	}

	// If there is only one entries and the direction is vertical, then set the direction to default
	if (entries?.length === 1 && direction === 'vertical') {
		componentSettings.direction = 'horizontal';
	}

	// Check if all objects in relatedContent-array has __typename "ContentfulKomponentInnhold"
	const allBasicContent = relatedContent?.every(
		x => x?.__typename === 'ContentfulKomponentInnhold'
	);

	// Check if all objects in relatedContent-array has a title or content
	let refsWithoutContent = entries?.filter(
		entry =>
			!entry?.title && !(entry?.content || entry?.desc || entry?.intro)
	);

	// If allBasicContent is false and there are items in the array refsWithoutContent, then return null
	if (!allBasicContent && !allImages && refsWithoutContent?.length !== 0) {
		return null;
	}

	return (
		<MaxWidth
			className="component__related-content max-width"
			data-cy="component__related-content">
			<Spacing {...props}>
				{componentSettings?.hidetitle !== 'true' && (
					<TitleAndText
						title={title}
						text={intro}
						buttons={buttons}
						settings={settings}
						headinglevel={headinglevel}
						headinglevelclass={
							allImages && props?.componentindex === 1
								? 'h3'
								: 'h2'
						}
						spacing={{
							bottom: (buttons?.length > 0 && 'medium') || 'none',
						}}
						nested={true}
					/>
				)}

				{(allImages && (
					<Images
						perrow={calculatePerRowValues(
							componentSettings?.perrow
						)}>
						{entries.map((entry, i) => (
							<Image key={i}>
								<LazyImage
									{...entry?.image}
									alt={entry?.description}
									ratio={'none'}
									caption={entry?.imagetext}
									clickable="false"
								/>
							</Image>
						))}
					</Images>
				)) ||
					(!allBasicContent && (
						<Wrapper
							$hasButtons={!!buttons?.length > 0}
							itemcount={
								entries?.length % 2 === 0 ? 'even' : 'odd'
							}
							perrow={componentSettings?.perrow}
							direction={componentSettings?.direction}>
							{entries.map((entry, i) => (
								<Article
									key={i}
									{...entry}
									direction={componentSettings?.direction}
									headinglevel="h3"
									imageclick={true}
									linkelement={
										componentSettings?.linkelement || 'text'
									}
									imagecrop="16:10"
									showTags={showTags}
								/>
							))}
						</Wrapper>
					)) || (
						<Columns
							columns={relatedContent}
							headinglevel="h3"
							parentshadow={componentSettings?.shadow}
							{...props}
						/>
					)}
			</Spacing>
		</MaxWidth>
	);
}

/**
 * Randomize the order of the entries if randomize is true, otherwise return the array as is
 * @param {array} array - The array to randomize
 * @param {boolean} shouldRandomize - Whether the array should be randomized
 * @returns {array} - The randomized array
 */
function randomizeEntries(array, shouldRandomize) {
	if (!shouldRandomize) return array;
	return array.sort(() => Math.random() - 0.5);
}

/**
 * Sort the array by date if randomize is false, otherwise randomize the order of the array
 * @param {array} array - The array to sort
 * @param {boolean} shouldRandomize - Whether the array should be randomized
 * @returns {array} - The sorted array
 */
function sortByDate(array, shouldRandomize) {
	return array.sort((a, b) => {
		if (shouldRandomize) {
			return Math.random() - 0.5;
		}
		return new Date(b?.date ?? 0) - new Date(a?.date ?? 0);
	});
}

/**
 * Remove duplicates from the array
 * @param {array} array - The array to remove duplicates from
 * @returns {array} - The array without duplicates
 */
function removeDuplicates(array) {
	return array.reduce((unique, o) => {
		if (!unique.some(obj => obj.id === o.id)) {
			unique.push(o);
		}
		return unique;
	}, []);
}

/**
 * Calculate the number of images per row for desktop, tablet and mobile
 */
function calculatePerRowValues(perrow) {
	// Ensure perrow is within the allowed range of 1 to 8
	const clampedDesktopPerRow = Math.max(1, Math.min(perrow, 8));

	// Calculate perrow for tablet (6 if perrow is 7 or 8, otherwise as is but not more than 6)
	const tabletPerRow = Math.min(
		6,
		clampedDesktopPerRow > 6 ? 6 : clampedDesktopPerRow
	);

	// Calculate perrow for mobile (3 if perrow is more than 3, otherwise as is)
	const mobilePerRow = Math.min(3, clampedDesktopPerRow);

	return {
		desktop: clampedDesktopPerRow,
		tablet: tabletPerRow,
		mobile: mobilePerRow,
	};
}
